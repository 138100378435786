import { Formik } from 'formik'
import firebase from 'gatsby-plugin-firebase'
import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { modalSlice } from '../redux/slice/modalSlice'
import { usersSlice } from '../redux/slice/users'

export default ({ data }) => {
  const dispatch = useDispatch()
  const modalShow = useSelector(state => state.modal.modalSlice)
  const getUsers = useSelector(state => state.users)

  const isShowModal = () => dispatch(modalSlice.actions.handleShow(true))
  const isCloseModal = () => dispatch(modalSlice.actions.handleShow(false))

  const isRegistered = () => dispatch(usersSlice.actions.changeRegistered(true))
  // const [users, setUsers] = useState([])
  // const [email, setEmail] = useState('')
  const [sendState, setSendState] = useState({
    submitting: false,
    status: null,
  })

  const handleOnSubmit = async (values, actions) => {
    await firebase
      .firestore()
      .collection('popup')
      .orderBy('created_at', 'desc')
      .get()
      .then(async snapshot => {
        for await (let doc of snapshot.docs) {
          doc.data().email === values.email
            ? rerun(resolved())
            : await firebase
                .firestore()
                .collection('popup')
                .doc()
                .set({
                  email: values.email,
                  created_at: new Date(),
                })
                .then(async () => {
                  await setSendState({ status: '登録完了' })
                  await isRegistered()
                  // console.log('Document successfully written!')
                })
                .catch(function (error) {
                  console.error('Error writing document: ', error)
                })
          return true
        }
        // setUsers(oldArray => [...oldArray, doc.data()])
      })
      .catch(async r => {
        await setSendState({ status: '登録済' })
        isRegistered()
        console.log(`catch callback: ${r}`)
        // isCloseModal()
      })
  }

  useEffect(() => {}, [])

  return (
    <>
      <Formik
        initialValues={{ email: '' }}
        initialStatus={{ errors: [], success: false }}
        onSubmit={handleOnSubmit}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('正しいメールアドレスを入力してください')
            .required('メールアドレスは必須です。'),
        })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          setStatus,
          status,
          touched,
          values,
          formik,
        }) => {
          if (sendState.status === null) {
            return (
              <Form className="text-left" onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="〇〇@gmail.com"
                    onChange={e => {
                      // console.info(status)
                      setStatus({ errors: [], success: false })
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                    isInvalid={!!(touched.email && errors.email)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="text-black-50 is-size-7">
                  <p>
                    メール登録だけで勝率アップが見込める【攻略ツール】のプレゼント特典！
                  </p>
                  <p>
                    *メルマガサービスから配信されます。万が一迷惑メールに振分けられる場合はご設定をお願いいたします。また無料メルマガ「メールトレードニュース」にも代理登録されます。メール文末からいつでも解除することができます。
                  </p>
                  <p>
                    ご入力頂きましたメールアドレスへは、上述のメルマガを配信する他、お客様のご連絡の目的でのみ使用します。
                  </p>
                </div>

                <div className="mt-5">
                  <Button
                    type="submit"
                    color="primary"
                    size="lg"
                    block
                    className="text-dark font-weight-bold"
                  >
                    メール登録をする
                  </Button>
                </div>
                {/* <div className="mt-5">
                <Button color="secondary" size="lg" block>
                  Google
                </Button>
              </div> */}
              </Form>
            )
          } else if (sendState.status === '登録完了') {
            return (
              <>
                <p className="text-center my-5">登録完了しました</p>
                <div className="mt-5">
                  <Button
                    color="primary"
                    size="lg"
                    block
                    className="text-dark font-weight-bold"
                    onClick={isCloseModal}
                  >
                    閉じる
                  </Button>
                </div>
              </>
            )
          } else if (sendState.status === '登録済') {
            return (
              <>
                <p className="text-center my-5">
                  すでに登録済みのメールアドレスでした！
                  <br />
                  ご入力ありがとうございます。
                </p>
                <div className="mt-5">
                  <Button
                    color="primary"
                    size="lg"
                    block
                    className="text-dark font-weight-bold"
                    onClick={isCloseModal}
                  >
                    閉じる
                  </Button>
                </div>
              </>
            )
          }
        }}
      </Formik>
    </>
  )
}
