import LocalImage from '@components/LocalImages'
import Modal from '@components/Modal'
import '@scss/gatstrap.scss'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Helmet from 'react-helmet'
// import { useCollectionData } from "react-firebase-hooks/firestore";

const HomePage = ({ data, filename }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const siteKeywords = data.site.siteMetadata.keywords

  return (
    <>
      <div className="wrapper">
        <Helmet
          htmlAttributes={{
            lang: 'ja',
          }}
          title={siteTitle}
          meta={[
            { name: 'description', content: `${siteDescription}` },
            { name: 'keyword', content: `${siteKeywords}` },
            {
              name: 'google-site-verification',
              content: `yRxCky8K5otg9YhMZJDARi_pBPRveHHm-FH4XpD9LyY`,
            },
          ]}
        >
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=UA-157125878-1`}
          />
          <script>
            {`window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'UA-157125878-1');
                `}
          </script>
        </Helmet>
        <header className="bg-dark">
          <div className="d-flex justify-content-center align-content-center py-3">
            <LocalImage
              src="logo.svg"
              alt="ハイローオーストラリア"
              className="header-logo"
            />
          </div>
        </header>
        <Modal />
        <main>
          <Container>
            <Row>
              <Col sm={12} md={5} className="mx-auto">
                <div className="modal-card">
                  <div className="modal-card-body">
                    <h2 className="title">Highlow.comにログイン</h2>
                    <a
                      className="login"
                      href="https://highlow.com/register?a_aid=5d74a21062504"
                    >
                      ログイン
                    </a>
                  </div>
                  <p className="new-register">
                    <a href="https://highlow.com/register?a_aid=5d74a21062504">
                      新しく口座開設はこちら
                    </a>
                  </p>
                  <div className="news">
                    <span className="news-title">
                      2019年6月の通知につきまして
                    </span>
                    <span className="news-text">
                      以後、サービス利用ドメインが【highlow.net】から【
                      <a
                        className="color-fccd2a"
                        href="https://highlow.com/register?a_aid=5d74a21062504"
                      >
                        highlow.com
                      </a>
                      】に変更となりました。すでにご利用いただいているお客様も新しく口座開設をされるお客様も従来通りご利用いただけます
                    </span>
                  </div>
                  <div className="footer-modal">
                    <ul>
                      <li>
                        <a href="top/assets/pdf/fsg_jp.pdf">
                          金融サービスに関するガイド
                        </a>
                      </li>
                      <li>
                        <a href="top/assets/pdf/pds_jp.pdf">金融商品開示文書</a>
                      </li>
                      <li>
                        <a href="top/assets/pdf/account_terms_jp.pdf">
                          契約条件
                        </a>
                      </li>
                      <li>
                        <a href="./policy.php">利用規則</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </main>
        <footer>
          <p>© ハイローオーストラリア.jp | All Rights Reserved</p>
        </footer>
      </div>
    </>
  )
}

export default HomePage

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
    allFile {
      edges {
        node {
          relativePath
          name
        }
      }
    }
  }
`
