import Form from '@components/Form'
// import Form from '@components/FormTest'
import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { modalSlice } from '../redux/slice/modalSlice'

export default ({ data }) => {
  const dispatch = useDispatch()
  const modalShow = useSelector(state => state.modal.modalSlice)
  const getUsers = useSelector(state => state.users)

  const isShowModal = async () => {
    // console.info(`ローカルストレージ${localRegistered()}`)
    // console.info(`redux : ${getUsers.registered}`)
    if (getUsers.registered === false || localRegistered === false) {
      dispatch(modalSlice.actions.handleShow(true))
    }
  }
  const isCloseModal = () => {
    dispatch(modalSlice.actions.handleShow(false))
  }

  const localRegistered = () =>
    localStorage.getItem('redux_localstorage_simple')
      ? JSON.parse(localStorage.getItem('redux_localstorage_simple')).users
          .registered
      : ''

  useEffect(() => {
    document.body.addEventListener('mouseleave', isShowModal, false)

    return () => {
      window.removeEventListener('mousemove', isShowModal)
    }
  }, [])

  return (
    <section>
      <Modal
        show={modalShow}
        onHide={isCloseModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger font-weight-bold is-size-5">
            メールアドレスの登録をお忘れではありませんか？
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form />
        </Modal.Body>
      </Modal>
    </section>
  )
}
